



















































import Vue from "vue";
import { NewPasswordApiFunc } from "@/api";
import BaseInputForm from "@/components/BaseInputForm.vue";
import BaseSubmitButton from "@/components/BaseSubmitButton.vue";

export type DataType = {
  email?: string;
  disableEmail: boolean;
  confirmationCode?: string;
  disableConfirmationCode: boolean;
  newPassword?: string;
  showSuccessMessage: boolean;
};

export default Vue.extend({
  name: "PasswordResetScreen",

  components: {
    BaseInputForm,
    BaseSubmitButton,
  },

  data(): DataType {
    return {
      email: undefined,
      disableEmail: false,
      confirmationCode: undefined,
      disableConfirmationCode: false,
      newPassword: undefined,
      showSuccessMessage: false,
    };
  },

  computed: {
    canSubmit(): boolean {
      return !!this.email && !!this.confirmationCode && !!this.newPassword && this.validatePassword(this.newPassword);
    },
  },

  methods: {
    validatePassword(password: string): boolean {
      const moreThan8character: boolean = password.length >= 8;
      const hasNumber: boolean = /\d/.test(password);
      const hasUpperCase: boolean = /[A-Z]/.test(password);
      const hasLowerCase: boolean = /[a-z]/.test(password);

      return moreThan8character && hasNumber && hasUpperCase && hasLowerCase;
    },

    async sendPasswordReset(): Promise<void> {
      if (!this.canSubmit) {
        return;
      }

      this.$setLoading(true);

      const { err } = await NewPasswordApiFunc().resetPassword(this.email, this.confirmationCode, this.newPassword);
      if (err) {
        this.$set(this.$error, "errMessage", "メールアドレスまたは認証キーが違います");

        this.$setLoading(false);
        return;
      }
      this.showSuccessMessage = true;
      this.$setLoading(false);
      this.$setChecked(true);
    },
  },
  mounted(): void {
    const paramEmail = this.$route.query.email;
    const paramKey = this.$route.query.key;

    if (paramEmail !== undefined) {
      this.email = paramEmail as string;
      this.disableEmail = true;
    }
    if (paramKey !== undefined) {
      this.confirmationCode = paramKey as string;
      this.disableConfirmationCode = true;
    }

    this.$setLoading(false);
    this.$setChecked(false);
    this.showSuccessMessage = false;
  },
});
